angular.module('app').filter('fieldNames', function () {
    return function (name) {
        var text;

        if(_.isUndefined(name)) return text;

        switch (name) {

            case "mobileNumber":
                text = "Mobile phone number";
                break;
            case "passengerName":
                text = "Passenger name";
                break;
            case "pickupLocation":
                text = "Pickup address";
                break;
            case "destinationLocation":
                text = "Destination address";
                break;
            case "cardNumber":
                text = "Credit card number";
                break;
            case "expiryMonth":
                text = "Credit card expiry month";
                break;
            case "expiryYear":
                text = "Credit card expiry year";
                break;
            case "cardCvv":
                text = "Credit card CVV";
                break;
            case "emailAddress":
                text = "Passenger email address";
                break;
            case "requestedPickupDateTime":
                text = "Request pickup time";
                break;
            default:
                text = name;
        }

        return text;

    };
});